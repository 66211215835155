body {
    
    background-image: linear-gradient(to bottom right, #49356B, #181F33);

}

.contact-container {
    color: #f7f1df;
    height: 100vh;

}

.form-div {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.border-div {
    border: 5px solid #f7f1df;
    padding: 5px;
}

h2 {
    font-size: 50px;
    text-align: center;
}

.input-box {
    display: flex;
    font-size: 24px;
    margin-bottom: 20px;
    justify-content: space-between;
}

.message-box {
    margin-top: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    margin-bottom: 20px;
}



.field {
    align-self: right;
    background-color: #f7f1df;
    font-size: 24px;
    width: 63%;
}

textarea {
    margin-left: 10px;
    background-color: #f7f1df;
    font-size: 24px;
    width: 100%;
    height: 200px;
}

.sumbit-button-div {
    margin-top: 30px;
    align-items: center;
    text-align: center;
}

.submit-button {
    background-color: transparent;
    color: #f7f1df;
    border: 4px solid #f7f1df;
    border-radius: 4px;
    font-size: 24px;
}

.submit-button:hover {
    background-color: #f7f1df;
    color: #181F33;
    
}