body {
    
    background-image: linear-gradient(to bottom right, #49356B, #181F33);

}
.homecontainer {
    color: #f7f1df;
    height: 100vh;
}

.contact-button {
    position: absolute;
    right: 50px;
    top: 50px;
    background-color: transparent;
    color: #f7f1df;
    border: 4px solid #f7f1df;
    border-radius: 4px;
    font-size: 24px;
}

.contact-button:hover {
    background-color: #f7f1df;
    color: #181F33;
}

.header {
    margin-top: 80px;
    margin-left: 50px;
    flex-direction: column;
}

h1 {
    font-size: 20vh;
    margin-bottom: 10vh;
    
}

.subtext {
    font-size: 20px;
    margin-left: 5vw;
}

.subtext2 {
    font-size: 70px;
    font-weight: bold;
    justify-content: center;
    text-align: center;
    margin-right: 15vw;
}